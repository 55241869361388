import { Button, CircularProgress, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import "./number-card.css";

const BLOCK = "number-card";

interface NumberCardProps {
  isLoading: boolean;
  amount: number | string;
  title: string;
  isDark?: boolean;
  tooltip?: string;
}

export const NumberCard = ({
  isLoading,
  amount,
  title,
  isDark,
  tooltip,
}: NumberCardProps) => {
  const classN = isDark ? `${BLOCK}-dark` : `${BLOCK}`;
  return (
    <div className={`${BLOCK}-base ${classN} mx-auto p-4`}>
      <div className="flex justify-between">
        <h4 className="text-sm font-bold">{title}</h4>
        {tooltip && (
          <Tooltip title={tooltip}>
            <Button
              style={{
                padding: 0,
                minWidth: 0,
              }}
            >
              <InfoIcon />
            </Button>
          </Tooltip>
        )}
      </div>
      {isLoading ? (
        <CircularProgress color="secondary" size={30} />
      ) : (
        <p className="text-base font-normal">{amount}</p>
      )}
    </div>
  );
};
