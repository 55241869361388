import React from "react";
import { BaseProps } from "../../../utils/types";
import { Graph } from "../../ui/Graph";
import { GraphContainer } from "../../ui/GraphContainer";
import { PRIMARY_COLOR } from "../../../utils/colors";
import { formatToMXN } from "../../../utils/money";
import { useRevenueBranches } from "../../../api/endpoints/get-revenue-branches";
const DEFAULT_COLORS = [PRIMARY_COLOR];

const getTitle = (type: string) => {
  switch (type) {
    case "sales":
      return "Ventas por tienda";
    case "current_cash":
      return "Dinero en kiosko por tienda";
    default:
      return "";
  }
};

const getLabel = (type: string) => {
  switch (type) {
    case "sales":
      return "Ventas";
    case "current_cash":
      return "Dinero en kiosko";
    default:
      return "";
  }
};

interface RevenueBranchesGraphProps extends BaseProps {
  type?: "sales" | "current_cash";
  width?: number;
  colors?: string[];
}
export const RevenueBranchesGraph = ({
  branches,
  from,
  to,
  type = "sales",
  width,
  colors = DEFAULT_COLORS,
}: RevenueBranchesGraphProps) => {
  const { data, isLoading } = useRevenueBranches({
    branches: branches,
    from: from,
    to: to,
    type,
  });

  const { keys, series } = React.useMemo(() => {
    if (!data || isLoading) {
      return {
        keys: [],
        series: [],
        cashSitting: 0,
      };
    }

    const sales = data
      .sort((a, b) => a.branch_name.localeCompare(b.branch_name))
      .reduce((acc, item) => {
        if (!acc[item.branch_name]) {
          acc[item.branch_name] = 0;
        }
        if (type === "current_cash") {
          acc[item.branch_name] += item?.cash_sitting ?? 0;
        } else {
          acc[item.branch_name] += item?.total_sales ?? 0;
        }
        return acc;
      }, {} as Record<string, number>);

    return {
      keys: Object.keys(sales),
      series: Object.values(sales),
    };
  }, [data, isLoading, type]);
  return (
    <GraphContainer>
      <Graph
        key="revenue-branches-graph"
        data={{
          xAxis: [{ keys }],
          series: [{ data: series, label: getLabel(type) }],
        }}
        title={getTitle(type)}
        colors={colors}
        width={width}
        seriesValueFormatter={(value) => formatToMXN(value)}
      />
    </GraphContainer>
  );
};
