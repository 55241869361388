import { useSearchParams } from "react-router-dom";

export const useTabUrlParam = (tabParam: string, defaultValue: number = 0) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = Number(searchParams.get(tabParam) || defaultValue);

  const setTab = (newValue: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(tabParam, newValue.toString());
      return newParams;
    });
  };

  return {
    currentTab,
    setTab,
  };
};
