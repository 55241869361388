import { DateRangePickerValue } from "@tremor/react";
import { useMemo, useState } from "react";
import {
  CURRENT_DAY,
  FIRST_DAY_OF_MONTH,
  toMexicoTimeZone,
} from "../../utils/date";
import { useSearchParams } from "react-router-dom";
import { useSetUrlParams } from "../use-set-url-params";

const FROM_PARAM = "from";
const TO_PARAM = "to";

export const useTimeFrameURLParam = () => {
  const [searchParams] = useSearchParams();
  const { fromParam, toParam } = useMemo(() => {
    return {
      fromParam: toMexicoTimeZone(
        searchParams.get(FROM_PARAM) ?? FIRST_DAY_OF_MONTH
      ),
      toParam: toMexicoTimeZone(searchParams.get(TO_PARAM) ?? CURRENT_DAY),
    };
  }, [searchParams]);

  const [timeFrame, setTimeFrame] = useState<DateRangePickerValue>({
    from: fromParam,
    to: toParam,
  });

  const { from, to } = useMemo(() => {
    if (!timeFrame.from) {
      return {
        from: "",
        to: "",
      };
    }

    const from = timeFrame.from.toISOString().split("T")[0];
    const to = timeFrame.to?.toISOString().split("T")[0];

    if (!to) {
      return {
        from,
        to: from,
      };
    }

    return {
      from,
      to,
    };
  }, [timeFrame]);

  useSetUrlParams({ [FROM_PARAM]: from, [TO_PARAM]: to });

  return {
    from,
    to,
    timeFrame,
    onTimeFrameChange: setTimeFrame,
  };
};
