import axios from "axios";

const PROD_URL = "https://api.mautra-analytics.com";
const DEV_URL = "http://localhost:4000";

export const instance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? PROD_URL : DEV_URL,
  timeout: 60000, // 60 seconds
});

instance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("itos_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      sessionStorage.removeItem("itos_token");
    }
    return Promise.reject(error);
  }
);
