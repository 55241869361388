import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTabUrlParam } from "../../../hooks/use-tab-url-param";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="w-auto"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 2 }}>{children}</Box>}
    </div>
  );
}

interface BasicTabsProps {
  tabs: {
    content: React.ReactNode;
    label: string;
  }[];
  tabParam: string;
  value: number;
  onValueChange: (event: React.SyntheticEvent, newValue: number) => void;
  defaultValue?: number;
}

export const BasicTabs = ({
  tabs,
  tabParam,
  value,
  onValueChange,
  defaultValue = 0,
}: BasicTabsProps) => {
  const { currentTab, setTab } = useTabUrlParam(tabParam, defaultValue);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          backgroundColor: "white",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="basic-tabs"
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
            },
          }}
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} label={tab.label} tabIndex={index} />;
          })}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => {
        return (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {tab.content}
          </CustomTabPanel>
        );
      })}
    </div>
  );
};
