import { CashVSCreditByDay } from "../CashVsCreditByDay";
import { TotalOperations } from "../TotalOperations";
import { useStats } from "../../../api/endpoints";
import { formatToMXN } from "../../../utils/money";
import { NumberCard } from "../../ui/NumberCard";
import { useTimeFrame } from "../../../hooks/time-frame/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";
import { RevenueTimeSeries } from "../RevenueTimeSeries";
import { RevenueBranchesGraph } from "../RevenueBranchesGraph";
import { useRevenueBranches } from "../../../api/endpoints/get-revenue-branches";
import React from "react";
import { SECONDARY_COLOR } from "../../../utils/colors";

export const PageRevenue = () => {
  const { from, to } = useTimeFrame();
  const { branches, totalBranches } = useBranches();
  const { data, isLoading } = useStats({
    branches,
    from,
    to,
  });
  const { data: revenueBranches, isLoading: isLoadingRevenueBranches } =
    useRevenueBranches({
      branches: branches,
      from: from,
      to: to,
      type: "current_cash",
    });

  const { cashSitting } = React.useMemo(() => {
    if (!revenueBranches || isLoadingRevenueBranches) {
      return {
        cashSitting: 0,
      };
    }

    const cashSitting = revenueBranches.reduce((acc, item) => {
      acc += item?.cash_sitting ?? 0;
      return acc;
    }, 0);

    return {
      cashSitting,
    };
  }, [revenueBranches, isLoadingRevenueBranches]);

  return (
    <div className="min-h-svh">
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.total_amount ?? 0)}
          title="Total ingresos en el kiosko"
          isDark
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_daily_sales ?? 0)}
          title="Promedio ingresos por día"
        />
        <NumberCard
          amount={formatToMXN(data?.credit_card_total ?? 0)}
          isLoading={isLoading}
          title={"Monto Tarjeta de crédito"}
        />
        <NumberCard
          amount={formatToMXN(data?.cash_total ?? 0)}
          isLoading={isLoading}
          title={"Monto Efectivo"}
        />
        <TotalOperations branches={branches} from={from} to={to} />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 my-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_operation_amount ?? 0)}
          title="Promedio operación kiosko"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.laundry_sales ?? 0)}
          title="Venta en usos de lavandería"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.water_sales ?? 0)}
          title="Venta en usos de agua"
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.last_movement ?? ""}
          title="Último movimiento"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(cashSitting)}
          title="Dinero en kiosko"
        />
      </div>
      <div className="lg:grid grid-flow-col grid-cols-2 gap-5 my-4">
        <RevenueTimeSeries branches={branches} from={from} to={to} />
        <CashVSCreditByDay branches={branches} from={from} to={to} />
      </div>
      <div className="grid gap-5">
        {totalBranches > 1 && (
          <RevenueBranchesGraph
            branches={branches}
            from={from}
            to={to}
            colors={[SECONDARY_COLOR]}
          />
        )}
        {totalBranches > 1 && (
          <RevenueBranchesGraph
            branches={branches}
            from={from}
            to={to}
            type="current_cash"
          />
        )}
      </div>
    </div>
  );
};
