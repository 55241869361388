import { BaseProps } from "../../../utils/types";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { MachineStatsByPeriodGraph } from "../MachineStatsByPeriodGraph";
import { BasicTabs } from "../../ui/Tabs";
import React from "react";
import { MachineDetailSummary } from "./MachineDetailSummary";
import { useGetMachineStatsByID } from "../../../api/endpoints/get-machine-stats-by-id";
import { MachineStatusesTable } from "../MachineStatusesTable";
import { useGetMachineStatuses } from "../../../api/endpoints/get-machine-statuses";
import { DatePicker } from "../../ui/DatePicker";
import { Drawer } from "@mui/material";
import { useTimeFrame } from "../../../hooks/time-frame/use-time-frame";

interface MachineDetailProps extends BaseProps {
  id: number | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export const MachineDetail = ({
  id,
  from,
  to,
  branches,
  isOpen,
  onClose,
}: MachineDetailProps) => {
  const { timeFrame, setTimeFrame } = useTimeFrame();
  const [tab, setTab] = React.useState<number>(0);
  const { data, isLoading } = useGetMachineStatsByID({
    id: id ?? 0,
    branches,
    from,
    to,
  });

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetMachineStatuses({
      branches,
      from,
      to,
      machineIds: id ? [id] : undefined,
    });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        "& .MuiDrawer-paper": {
          width: {
            sm: "100%",
            md: "60%",
          },
        },
      }}
      onClose={onClose}
    >
      <div className="grid md:grid-cols-2 my-2 px-4">
        <div className="mb-4 font-bold">{data?.machine_name}</div>
        <div className="md:flex md:flex-col md:items-end">
          <DatePicker
            key={"machine-detail"}
            value={timeFrame}
            onValueChange={setTimeFrame}
            className="mb-2"
          />
          <Button variant="outlined" onClick={onClose} size="small">
            Cerrar
          </Button>
        </div>
      </div>
      <Divider />
      <div className="px-3">
        <BasicTabs
          tabParam="machDetailTab"
          tabs={[
            {
              label: "Resumen",
              content: (
                <MachineDetailSummary
                  data={data}
                  statuses={statuses}
                  isLoading={isLoading}
                  isLoadingStatuses={isLoadingStatuses}
                  branches={branches}
                  from={from}
                  to={to}
                />
              ),
            },
            {
              label: "Detalle",
              content: (
                <div>
                  <MachineStatsByPeriodGraph
                    from={from}
                    to={to}
                    branches={branches}
                    machineIds={[id ?? 0]}
                  />
                  <div className="mt-4">
                    <div className="mb-3 text-black-500 font-bold">
                      Historial de estados
                    </div>
                    <MachineStatusesTable
                      data={statuses}
                      isLoading={isLoadingStatuses}
                    />
                  </div>
                </div>
              ),
            },
          ]}
          value={tab}
          onValueChange={(_, t) => setTab(t)}
        />
      </div>
    </Drawer>
  );
};
