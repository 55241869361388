import { instance as ax } from "../setup";

export const postLogin = async (email: string, password: string) => {
  let url = "/login";
  const response = await ax
    .post(
      url,
      { email, password },
      { headers: { "content-type": "multipart/form-data" } }
    )
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem("itos_token", response.data.token);
        return { isSuccess: true };
      }
      return { isSuccess: false };
    })
    .catch((error) => {
      return { isSuccess: false };
    });

  return response;
};
