import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useUrlParam = (key: string, value: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (Array.isArray(value) && value.length === 0) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }

    navigate(
      {
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, [key, value, location.search, navigate]);
};
